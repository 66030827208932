import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const CatalogRoute = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/catalog"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Catalogos</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/catalog/categories"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Catalogos categorías</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/catalog/products"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Catalogos productos y precios</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/catalog/complements"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Catalogos complementos</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/catalog/modifiers"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Catalogos modificadores</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/catalog/menu"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Catalogos menú</div>
                    </Suspense>}
                />
            </Routes>
        </>
    )

}
export default CatalogRoute