import React from "react";
import './InputSeach.scss'
import searchIcon from '../../../assets/icons/searchIcon.svg'
const InputSeach = ()=>
{
    return(
        <>
        <div className="input-wrapper">
            <input type="search" class="input" placeholder="Buscar módulo"/>
            <img className="input-icon" src={searchIcon} alt="" />
        </div>
            
        </>
    )
}
export default InputSeach