import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const CashregisterRoute = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/cashregister"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Caja</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/cashregister/categories"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Caja categorías</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/cashregister/products"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Caja productos y precios</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/cashregister/complements"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Caja complementos</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/cashregister/modifiers"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Caja modificadores</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/cashregister/menu"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Caja menú</div>
                    </Suspense>}
                />
            </Routes>
        </>
    )

}
export default CashregisterRoute