import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const SalesRoute = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/sales"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Ventas</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/sales/categories"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Ventas categorías</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/sales/products"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Ventas productos y precios</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/sales/complements"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Ventas complementos</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/sales/modifiers"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Ventas modificadores</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/sales/menu"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Ventas menú</div>
                    </Suspense>}
                />
            </Routes>
        </>
    )

}
export default SalesRoute