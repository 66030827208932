import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const UsersRoute = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/users"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Usuarios</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/users/categories"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Usuarios categorías</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/users/products"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Usuarios productos y precios</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/users/complements"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Usuarios complementos</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/users/modifiers"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Usuarios modificadores</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/users/menu"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Usuarios menú</div>
                    </Suspense>}
                />
            </Routes>
        </>
    )

}
export default UsersRoute