import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const TablesRoute = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/tables"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Mesas</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/tables/categories"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Mesas categorías</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/tables/products"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Mesas productos y precios</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/tables/complements"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Mesas complementos</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/tables/modifiers"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Mesas modificadores</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/tables/menu"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Mesas menú</div>
                    </Suspense>}
                />
            </Routes>
        </>
    )

}
export default TablesRoute