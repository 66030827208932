import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const PromotionsRoute = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/promotions"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Promociones</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/promotions/categories"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Promociones categorías</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/promotions/products"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Promociones productos y precios</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/promotions/complements"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Promociones complementos</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/promotions/modifiers"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Promociones modificadores</div>
                    </Suspense>}
                />
                <Route
                    exact
                    path="/promotions/menu"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Promociones menú</div>
                    </Suspense>}
                />
            </Routes>
        </>
    )

}
export default PromotionsRoute