import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../components/Molecules/Header/Header";
import './MainRouter.scss'
import SideMenu from "../components/Molecules/SideMenu/SideMenu";
import DashboardRoute from "./Routes/Dashboard";
import CatalogRoute from "./Routes/Catalog";
import SalesRoute from "./Routes/sales";
import SalesTypeRoute from "./Routes/salestype";
import PromotionsRoute from "./Routes/promotions";
import CashregisterRoute from "./Routes/cashregister";
import ReservationsRoute from "./Routes/reservations";
import BoardsRoute from "./Routes/boards";
import UsersRoute from "./Routes/users";
import TablesRoute from "./Routes/Tables";
import ReportsRoute from "./Routes/reports";
import ConfigRoute from "./Routes/Config";
import HelpRoute from "./Routes/Help";
//assets

const MainRouter = () => {

    return (
        <>
            <main className="app">
                <Router>
                    <div className="structure">
                        <div className="headerPosition">
                            <Header></Header>
                        </div>
                        <div className="sidebarPosition">
                            <SideMenu></SideMenu>
                        </div>
                        <div className="content">
                                <DashboardRoute></DashboardRoute>
                                <CatalogRoute></CatalogRoute>
                                <SalesRoute></SalesRoute>
                                <SalesTypeRoute></SalesTypeRoute>
                                <PromotionsRoute></PromotionsRoute>
                                <CashregisterRoute></CashregisterRoute>
                                <ReservationsRoute></ReservationsRoute>
                                <BoardsRoute></BoardsRoute>
                                <UsersRoute></UsersRoute>
                                <TablesRoute></TablesRoute>
                                <ReportsRoute></ReportsRoute>
                                <ConfigRoute></ConfigRoute>
                                <HelpRoute></HelpRoute>
                        </div>
                    </div>
                </Router>

            </main>

        </>
    )
}
export default MainRouter