import React, { useState } from "react";
import './Header.scss'
import logo from '../../../assets/img/logo.png'
import InputSeach from "../../atoms/InputSeach/InputSeach";
import notification from '../../../assets/icons/Notificaciones.svg'
import arrowDown from '../../../assets/icons/arrowDown.svg'
import avatar from '../../../assets/img/AvatarTest.png'
const Header = () => {
    const [name] = useState("María Hernández")
    return (
        <>
            <header>
                <div className="headerContainer">
                    <div className="headerLogo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="searchEnginePosition">
                        <div className="searchEngine">
                            <InputSeach></InputSeach>
                        </div>
                        <img src={notification} alt="" className="notificationIcon" />
                        <div className="nameContainer">
                            <div className="name">{name}</div>
                            <img src={arrowDown} alt="" />

                        </div>
                            <img src={avatar} alt="" className="avatar"/>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header