import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const ReservationsRoute = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/reservations"
                    element={<Suspense fallback={<>skeleton</>}>
                        <div style={{ fontSize: "40px", color: "white" }}>Reservaciones</div>

                    </Suspense>}
                />
            </Routes>
        </>
    )

}
export default ReservationsRoute