import React, { useEffect, useState } from "react";
import './SideMenu.scss'
//assets
import arrowDown from '../../../assets/icons/arrowDown.svg'
import dashboard from '../../../assets/icons/dashboard.svg'
import catalog from '../../../assets/icons/catalog.svg'
import sales from '../../../assets/icons/sales.svg'
import salesType from '../../../assets/icons/salesType.svg'
import promo from '../../../assets/icons/promo.svg'
import caja from '../../../assets/icons/caja.svg'
import reservations from '../../../assets/icons/reservations.svg'
import tableros from '../../../assets/icons/tableros.svg'
import users from '../../../assets/icons/users.svg'
import tables from '../../../assets/icons/tables.svg'
import reports from '../../../assets/icons/reports.svg'
import config from '../../../assets/icons/config.svg'
import help from '../../../assets/icons/help.svg'


import { Link, useLocation, useNavigate } from "react-router-dom";

const SideMenu = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [configPint, setconfigPint] = useState(false)
    const [helpPint, sethelpPint] = useState(false)
    const [menu, setMenu] = useState([
        {
            icon: dashboard,
            title: "Dashboard",
            url: "/dashboard",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: []
        },
        {
            icon: catalog,
            title: "Catálogo",
            url: "/catalog",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: [
                {
                    title: "Categorías",
                    link: '/catalog/categories',
                    selected: false
                },
                {
                    title: "Productos y precios",
                    link: '/catalog/products',
                    selected: false
                },
                {
                    title: "Complementos",
                    link: '/catalog/complements',
                    selected: false
                },
                {
                    title: "Modificadores",
                    link: '/catalog/modifiers',
                    selected: false
                },
                {
                    title: "Menús y recetas",
                    link: '/catalog/menu',
                    selected: false
                },

            ]
        },
        {
            icon: sales,
            title: "Ventas",
            url: "/sales",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: [
                {
                    title: "Categorías",
                    link: '/sales/categories',
                    selected: false
                },
                {
                    title: "Productos y precios",
                    link: '/sales/products',
                    selected: false
                },
                {
                    title: "Complementos",
                    link: '/sales/complements',
                    selected: false
                },
                {
                    title: "Modificadores",
                    link: '/sales/modifiers',
                    selected: false
                },
                {
                    title: "Menús y recetas",
                    link: '/sales/menu',
                    selected: false
                },

            ]
        },
        {
            icon: salesType,
            title: "Tipos de venta",
            url: "/saletype",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: []
        },
        {
            icon: promo,
            title: "Promociones",
            url: "/promotions",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: [
                {
                    title: "Categorías",
                    link: '/promotions/categories',
                    selected: false
                },
                {
                    title: "Productos y precios",
                    link: '/promotions/products',
                    selected: false
                },
                {
                    title: "Complementos",
                    link: '/promotions/complements',
                    selected: false
                },
                {
                    title: "Modificadores",
                    link: '/promotions/modifiers',
                    selected: false
                },
                {
                    title: "Menús y recetas",
                    link: '/promotions/menu',
                    selected: false
                },

            ]
        },
        {
            icon: caja,
            title: "Caja",
            url: "/cashregister",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: [
                {
                    title: "Categorías",
                    link: '/cashregister/categories',
                    selected: false
                },
                {
                    title: "Productos y precios",
                    link: '/cashregister/products',
                    selected: false
                },
                {
                    title: "Complementos",
                    link: '/cashregister/complements',
                    selected: false
                },
                {
                    title: "Modificadores",
                    link: '/cashregister/modifiers',
                    selected: false
                },
                {
                    title: "Menús y recetas",
                    link: '/cashregister/menu',
                    selected: false
                },

            ]
        },
        {
            icon: reservations,
            title: "Reservaciones",
            url: "/reservations",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: []
        },
        {
            icon: tableros,
            title: "Tableros",
            url: "/boards",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: [
                {
                    title: "Categorías",
                    link: '/boards/categories',
                    selected: false
                },
                {
                    title: "Productos y precios",
                    link: '/boards/products',
                    selected: false
                },
                {
                    title: "Complementos",
                    link: '/boards/complements',
                    selected: false
                },
                {
                    title: "Modificadores",
                    link: '/boards/modifiers',
                    selected: false
                },
                {
                    title: "Menús y recetas",
                    link: '/boards/menu',
                    selected: false
                },

            ]
        },
        {
            icon: users,
            title: "Usuarios",
            url: "/users",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: [
                {
                    title: "Categorías",
                    link: '/users/categories',
                    selected: false
                },
                {
                    title: "Productos y precios",
                    link: '/users/products',
                    selected: false
                },
                {
                    title: "Complementos",
                    link: '/users/complements',
                    selected: false
                },
                {
                    title: "Modificadores",
                    link: '/users/modifiers',
                    selected: false
                },
                {
                    title: "Menús y recetas",
                    link: '/users/menu',
                    selected: false
                },

            ]
        },
        {
            icon: tables,
            title: "Mesas",
            url: "/tables",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: [
                {
                    title: "Categorías",
                    link: '/tables/categories',
                    selected: false
                },
                {
                    title: "Productos y precios",
                    link: '/tables/products',
                    selected: false
                },
                {
                    title: "Complementos",
                    link: '/tables/complements',
                    selected: false
                },
                {
                    title: "Modificadores",
                    link: '/tables/modifiers',
                    selected: false
                },
                {
                    title: "Menús y recetas",
                    link: '/tables/menu',
                    selected: false
                },

            ]
        },
        {
            icon: reports,
            title: "Reportes",
            url: "/reports",
            showSubmenu: false,
            selectedMenu: false,
            subMenu: []
        }
    ])

    useEffect(() => {
        const menuCopy = [...menu]
        menuCopy.map((item, keyMap) => {
            if (location.pathname.includes(item.url)) {
                item.selectedMenu = true
            
                if (item.subMenu.length > 0) {
                    console.log(" location.pathname", location.pathname);
                    item.subMenu.map((itemSub, keySup) => {
                        if (itemSub.link == location.pathname) {
                            itemSub.selected = true;
                        }
                        else {
                            itemSub.selected = false;
                        }
                    })
                }
            }
            else {

                item.selectedMenu = false
            }
        })

        
        if (location.pathname == "/config") {
            setconfigPint(true)
        }
        else {
            setconfigPint(false)
        }
        if (location.pathname == "/help") {
            sethelpPint(true)
        }
        else {
            sethelpPint(false)
        }
        console.log(menu);
        setMenu(menuCopy)
    }, [location])


    const changeStatusMenu = (key) => {
        const menuCopy = [...menu]
        menuCopy.map((item, keyMap) => {
            if (keyMap != key) {
                item.showSubmenu = false
            }
        })
        menuCopy[key].showSubmenu = !menuCopy[key].showSubmenu
        setMenu(menuCopy)

    }
       
    return (
        <>
            <div className="SideMenuContainer">
                <div className="SideMenu">
                    <div className="menuOptions">
                        {
                            menu.map((item, key) => {

                                return (
                                    <>

                                        <div className={`hoverSection ${item.selectedMenu ? "backgroundEx" : ""}`} onClick={() => { navigate(item.url) }}>
                                            <div className="titleSection" >
                                                <div className="nameAndIcon" >
                                                    <img src={item.icon} alt="" className="iconMenu" />
                                                    <div className="titleMenu">{item.title}</div>
                                                </div>
                                                {
                                                    item.subMenu.length > 0 ?
                                                        <>
                                                            <div className="arrowDownContainer">
                                                                {
                                                                    item.showSubmenu ?
                                                                        <>

                                                                            <img src={arrowDown} alt="" className="arrowDown img180" onClick={() => { changeStatusMenu(key) }} />
                                                                        </> :
                                                                        <>
                                                                            <img src={arrowDown} alt="" className="arrowDown" onClick={() => { changeStatusMenu(key) }} />
                                                                        </>

                                                                }
                                                            </div>
                                                        </> : ""
                                                }


                                            </div>
                                        </div>
                                        {
                                            item.showSubmenu == true ?
                                                <>
                                                    <div className="subMenu">


                                                        {
                                                            item.subMenu.map((itemSubMenu, keySubMenu) => {
                                                                return (
                                                                    <>
                                                                        <div className={`subMenuContainer ${itemSubMenu.selected ? "selectedSubmenu" : ""}`} onClick={() => { navigate(itemSubMenu.link) }}>
                                                                            <div className="sumMenuText">{itemSubMenu.title}</div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </> : ""
                                        }
                                    </>
                                )

                            })
                        }


                    </div>
                    <div className="bottomOptions">
                        <div className="hrMenu" />
                        <div className={`hoverSection ${configPint ? "backgroundEx" : ""}`} style={{ marginTop: "20px" }} onClick={() => { navigate("/config") }}>
                            <div className="titleSection" >
                                <div className="nameAndIcon" >
                                    <img src={config} alt="" className="iconMenu" />
                                    <div className="titleMenu">Configuración</div>
                                </div>
                            </div>
                        </div>
                        <div className={`hoverSection  ${helpPint ? "backgroundEx" : ""}`} onClick={() => { navigate("/help") }}>
                            <div className="titleSection" >
                                <div className="nameAndIcon" >
                                    <img src={help} alt="" className="iconMenu" />
                                    <div className="titleMenu">Ayuda</div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default SideMenu
